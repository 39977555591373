<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>

        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane :label="item.name+'消息'" :name="item.key" v-for="(item,index) in platformList" :key="index">
                    <div style="float: left;margin-bottom:20px" v-if="item.key === 'h5' || item.key === 'wxapp'">
                        <el-button type="primary" @click="sync(item.key)" v-if="isSync">同步{{item.name}}消息</el-button>
                        <el-button type="primary" v-else><i class="el-icon-loading"></i>同步中</el-button>
                    </div>
                    <div style="float: left;margin-bottom:20px" v-else>
                        <el-button type="primary" @click="add()">新增</el-button>
                    </div>
                    <!-- 表格内容 -->
                    <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 50px;">
                        <el-table-column label="id" prop="id" align="center" width="80"></el-table-column>
                        <el-table-column label="模板id" prop="template_id" align="center" width="150"></el-table-column>
                        <el-table-column label="模板编码" prop="code" align="center" width="150"></el-table-column>
                        <el-table-column label="模板名称" prop="template_name" align="center" width="150"></el-table-column>
                        <el-table-column label="模板内容" prop="content" align="center"></el-table-column>
                        <el-table-column label="状态" prop="state" align="center" width="120">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="170" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                                <el-button type="text" icon="el-icon-delete" @click="remove(scope.row.id)">删除</el-button>
                                <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                                </el-button>
                                <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                                    上线</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 上线、下线提示框 -->
                    <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                        <div class="del-dialog-cnt">{{ confirmContent }}</div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="confirmVisible = false">取 消</el-button>
                            <el-button type="primary" @click="stateData">确 定</el-button>
                        </span>
                    </el-dialog>
                    <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
                </el-tab-pane>
            </el-tabs>
            <!-- 新增 编辑 -->
            <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
                <el-form ref="form" :model="form" label-width="140px" :rules="rules" label-position="left">
                    <el-form-item label="模板编码" prop="code">
                        <el-select v-model="form.code" placeholder="请选择模板编码">
                            <el-option v-for="(items,index) in message" :key="index" :label="items.name" :value="items.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模板名称" prop="template_name">
                        <el-input v-model="form.template_name" placeholder="请输入模板名称"></el-input>
                    </el-form-item>
                    <el-form-item label="模板内容" prop="content">
                        <el-input type="textarea" autosize placeholder="请输入模板内容" v-model="form.content"> </el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">上线</el-radio>
                            <el-radio :label="1">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            syncUrl: '',
            activeName: 'h5',
            isSync: true,
            isWeixinApplate: true,
            message: [],
            rules: {
                template_id: [{
                    required: true,
                    message: '模板id不能为空',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '模板编码不能为空',
                    trigger: 'blur'
                }],
                template_name: [{
                    required: true,
                    message: '模板名称不能为空',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '模板内容不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            menuList: ['消息', '消息模板'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                platform: 'h5',
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            form: {
            },
            mode: 'add',
            title: '编辑',
            platformList: [
                { key: "h5", name: "h5" },
                { key: "wxapp", name: "微信小程序" }
            ]
        };
    },
    created() {
        this.loadData()
        this.getconf()
    },
    methods: {
        getconf() {
            request.get('/common/conf').then(ret => {
                if (ret.code == 1) {
                    // this.platformList = ret.data.platform
                    this.message = ret.data.message
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // tab切换
        handleClick(tab) {
            this.isSync = true
            this.queryParams.platform = tab.paneName
            this.loadData()
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        cellstyle() {
            return 'text-align: center';
        },
        loadData() {
            request.get('/common/message/template/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增
        add() {
            this.form = {
                platform: this.activeName,
                state: 2
            }
            this.mode = 'add'
            this.addVisible = true;
        },
        // 同步
        sync(ty) {
            this.isSync = false
            switch (ty) {
                case 'h5':
                    this.syncUrl = "/common/message/template/weixin/syn"
                    this.queryParams.platform = ty
                    this.syncRequest()
                    break
                case 'wxapp':
                    this.syncUrl = "/common/message/template/weixinmp/syn"
                    this.queryParams.platform = ty
                    this.syncRequest()
                    break
            }

        },
        syncRequest() {
            request.post(this.syncUrl).then(ret => {
                if (ret.code == 1) {
                    this.isSync = true
                    this.loadData()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit'
            this.form = {}
            const item = this.pageInfo.list[index];
            this.form = Object.assign({}, item);
            this.addVisible = true;

        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        var url = this.mode == 'add' ? '/common/message/template/add' : '/common/message/template/edit';
                        // var url = '/common/message/template/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/common/message/template/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 删除
        remove(id) {
            request.post('/common/message/template/remove', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('删除成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        }
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box >>> .el-textarea {
    width: 80%;
}
.dialog_box >>> .el-input--small .el-input__inner {
    width: 148%;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>